import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProseDark from "../components/prose-dark"
import ProseLight from "../components/prose-light"

import BgWebM_LG from "../assets/BG-WEBM-LG.webm"
import Bg264_LG from "../assets/BG-264.mp4"
import HeroText from "../components/utility/heroText"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.staff = props.data.allStaffYaml.nodes
    this.video = undefined
    this.state = {
      start: false,
      loading: true
    };
  }

  componentDidMount() {
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    sleep(100).then(() => {
        this.setState({ loading: false });
    })
    sleep(300).then(() => {
        this.setState({ start: true });
    })

    // if (this.video) {
    //   this.video.addEventListener("loadeddata", () => {
    //     this.setState({ loading: false });
    //   });
    // }
  }

  componentWillUnmount() {
    // if (this.video) {
    //   this.video.removeEventListener("loadeddata", () => {
    //   });
    // }
  }
  
  render() {

  return(
  <Layout>
    <SEO title="Home" />
    <video 
       className={`absolute top-0 w-screen h-96 md:h-screen object-cover transition-opacity duration-1000 ${this.state.loading ? 'opacity-0' : 'opacity-100'}`}
      loop muted autoPlay playsInline eager
    >
      <source src={BgWebM_LG} type="video/webm" />
      <source src={Bg264_LG} type="video/mp4" />
    </video>
    <div className="min-h-screen mt-0 border-[transparent] border-2">
    <div className="relative z-30 w-full px-8 md:px-20">
    <div className="mt-40 md:mt-[400px] mb-48 lg:w-1/2">
      <ProseDark>

        <h1><HeroText hasDot="true">We are a provider of communication services and community support to special purpose districts</HeroText></h1>
        <div className={`transition-opacity duration-300 ${this.state.start ? 'opacity-100' : 'opacity-0'}`}><p>By engaging Touchstone, your District is making a commitment to active, transparent, and accurate communication with the residents and commercial properties within your community. By taking this step, Touchstone will help you cultivate communication methods and tools to elevate your District’s message and reach to your residents.</p>
        <p>We are experienced in district communications. We are more than a standard commercial promotions company, public website vendor, or single service company. We believe we have the strongest ability and the right skills to help your District modernize your communication strategy.</p></div>

      </ProseDark>
    </div>
    </div>
    </div>
    <div className="bg-zinc-100 w-full px-8 py-36">
    <div className="m-auto w-full container">
      <ProseLight>
        <h1 className="text-7xl leading-none text-center">Our Services<span className="text-yellow-500">.</span></h1>
        <p className="text-center font-medium">Are you looking for a particular service for your District? Select from the options below!</p>
        <div className="grid lg:grid-cols-3 gap-20 mt-16 text-center">

          <Link to="/election-services" className="no-underline font-normal text-inherit transition-all duration-200 bg-white rounded-3xl shadow-xl hover:shadow-2xl hover:-translate-y-4 px-8 py-8 max-w-lg m-auto h-full">
            <StaticImage  placeholder="tracedSVG" className="w-2/3 h-56 m-auto" src="../assets/election-services.png" objectFit="contain" alt="" />
            <p className="text-3xl">Election Services</p>
            <p>Touchstone understands elections, and we understand the hurdles that can influence the results of your next initiative.</p>
          </Link>

          <Link to="/general-communications" className="no-underline font-normal text-inherit transition-all duration-200 bg-white rounded-3xl shadow-xl hover:shadow-2xl hover:-translate-y-4 px-8 py-8 max-w-lg m-auto h-full">
            <StaticImage  placeholder="tracedSVG" className="w-2/3 h-56 m-auto"  src="../assets/general-communications.png" objectFit="contain" alt="" />
            <p className="text-3xl">General Communications</p>
            <p>Your message is our priority, and your good stewardship is paramount to our strategy.</p>

          </Link>
          <Link to="/district-websites" className="no-underline font-normal text-inherit transition-all duration-200 bg-white rounded-3xl shadow-xl hover:shadow-2xl hover:-translate-y-4 px-8 py-8 max-w-lg m-auto h-full">
            <StaticImage  placeholder="tracedSVG" className="w-2/3 h-56 m-auto" src="../assets/district-websites.png" objectFit="contain" alt="" />
            <p className="text-3xl">District Websites</p>
            <p>Touchstone offers customized options for district websites, all while maintaining website compliance.</p>

          </Link>
        </div>
      </ProseLight>
    </div>
    </div>
    <div className="bg-zinc-900 w-full px-8 py-36">
    <div className="m-auto w-full container">
      <ProseDark>
        <div className="grid md:grid-cols-2 gap-12">

        <h1 className="text-4xl md:text-5xl leading-none text-yellow-400">Who is Touchstone?</h1>
        <div>

        <p>The idea of communication is as old as the spoken word. Over the centuries, relaying an idea from one person to another or from group to group has not lost its importance.</p>
        <p>Our goal is to bring the idea of simple and transparent communication to special purpose districts; less difficult for the Board, and less confusing for the district residents and commercial clients. Clear, factual, relevant information as the moment requires.</p>
        </div>
        </div>
        <hr />
        <h2 className="text-7xl md:text-7xl lg:text-[8rem] text-white m-0 font-extrabold">Meet our team<span className="text-yellow-400">.</span></h2>
        <div className="grid lg:grid-cols-4 gap-8 mt-16">
          <div className=""></div>
          <div className="col-span-3 flex justify-center lg:justify-end flex-wrap">
            { this.staff.map(node => {
              const photo = getImage(node.photo)
              
              return (

            <div className="w-80 text-lg mr-8 mb-8">
              <GatsbyImage objectFit="contain" className="rounded-xl" image={photo} alt="" />
              <p className="leading-tight">
                <a className="transition-all float-right ml-4 mb-4 mr-4 mt-1 text-zinc-800 hover:text-zinc-700 hover:scale-110" href={`mailto:${node.email}`} >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                </a>
                <span className="text-3xl font-extrabold">{node.name}</span><br />
                <span className="text-zinc-400 leading-tight">{node.role}</span>
              </p>
            </div>
            )})}

          </div>

        </div>
      </ProseDark>
    </div>
    </div>
  </Layout>
)
}
}

export default IndexPage

export const homepageQuery = graphql`
query homepageQuery {
  allStaffYaml {
    nodes {
      name
      photo {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG, 
            width: 640,
            tracedSVGOptions: {blackOnWhite: false},
            quality: 95
            )
        }
      }
      email
      role
    }
  }
}`