import React from "react"

class HeroText extends React.Component {
    constructor(props) {
        super(props)
        this.words = props.children.split(" ");
        this.wordSet = this.words.map((word) => {
            return {
                "word": word,
                "loaded": false,
            }
        })

        this.state = { 
            loaded: false,
            wordSet: this.wordSet
        }
    }
    componentDidMount() {
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        sleep(400).then(() => {
            this.setState({ loaded: true });
        })
        this.state.wordSet.map((word, key) => {
            sleep(25*key).then(() => {
                let newWordSet = { ...this.state.wordSet }
                newWordSet[key].loaded = true
                
                this.setState({ wordSet: newWordSet });
            })

        })
    }
    render() {
        return (
        <>
            {this.wordSet.map((wording, key) => {
                const delay = `delay-[${key*100}ms]`
                return (<span key={key} className={`inline-block duration-300 transition-all mr-2 ${wording.loaded ? 'opacity-100 translate-y-0': 'translate-y-6 opacity-0'}  ${delay}`}>{` ${wording.word}`}</span>)
            })}
            {this.props.hasDot ? (<span className={`duration-300 transition-all inline-block text-yellow-400 -ml-2 ${this.state.loaded ? 'opacity-100 translate-y-0': 'translate-y-6 opacity-0'}`}>.</span>):(<></>)}
        </>
        )
    }
}

export default HeroText